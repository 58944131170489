import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { filterBookings, getBookingDetails, getTenant, setBookingFilters } from "../../Store/bookings/BookingsActions";
import Select from 'react-select';
import ClearIcon from "@material-ui/icons/Clear";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Table, {Data} from "../Table/Table";
import {calculatePrice, getBadgeClass, getReadWritePermission, getStatus, price, updateFilters} from "../../Util/utils";
import IntlMessages from "../../Util/IntlMessages";
import RootState from "../../Store/RootState";
import { BookingFilters, BookingState, Commission, Contact, ServiceType, Tenant, filterInitialState } from "../../Store/bookings/bookingsTypes";
import { ChannelData } from "../../Store/channel/channelTypes";
import { useHistory } from "react-router-dom";
import CsvDownload from "../CsvDownload";
import { Cell, Column, Row } from "react-table";

const commissionRolePriority = ["platform provider", "service provider", "tenant", "reseller", "location owner", "advertiser"];

const getCommission = (commissions: Commission, scopes: string[]) => {
	for (const role of commissionRolePriority) {
			if (scopes.some(scope => scope.includes(role.replace(' ', '_')))) {
					const contractKey = Object.keys(commissions.total).find(key => commissions.total[key].role === role);
					if (contractKey) {
							const contract = commissions.total[contractKey];
							return {
									currencyCode: contract.currency.code,
									commission: contract.commission,
									currencyExponent: contract.currency.exponent
							};
					}
			}
	}
	return null;
};

const useStyles = makeStyles((theme) => ({
    date: {
        display: "flex",
        flexWrap: 'wrap',
        maxWidth: '400px',
        width: '100%'
    },
    filters: {
        display: 'flex',
        gap: '10px'
    },
    selectors: {
        flexDirection: 'column',
        display: "flex",
        flexWrap: 'wrap',
        maxWidth: '400px',
        width: '100%',
        gap: '17px'
    },
    input: {
        borderRadius: theme.shape.borderRadius,
        transition: theme.transitions.create("width"),
        width: '100%',
        /**
         * calendar icon inside the date filters
         */
        '& div': {
            '& div': {
                '& button': {
                    padding: '12px 0'
                }
            }
        }
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'self-end'
    },
    radioInput: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        transition: theme.transitions.create("width"),
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap'
    },
    button: {
        margin: theme.spacing(1),
        height: '3em'
    },
    iconButton: {
        padding: theme.spacing(0)
    },
    email: {
        fontSize: "10px",
    },
}));

const BookingList = (): JSX.Element => {

	const serviceOptions = [
		{
			value: ServiceType.delivery,
			label: <IntlMessages id={"booking_list:service:delivery"} />
		},
		{
			value: ServiceType.injection,
			label: <IntlMessages id={"booking_list:service:injection"} />
		},
		{
			value: ServiceType.storage,
			label: <IntlMessages id={"booking_list:service:storage"} />
		}
	]

	const statusOptions = [
		{
			value: "STATUS.PROGRESS.002",
			label: <IntlMessages id={"booking_list:status:ready"} />
		},
		{
			value: "STATUS.PROGRESS.005",
			label: <IntlMessages id={"booking_list:status:in_progress"} />
		},
		{
			value: "STATUS.PROGRESS.007",
			label: <IntlMessages id={"booking_list:status:completed"} />
		},
		{
			value: "STATUS.ERROR.001",
			label: <IntlMessages id={"booking_list:status:cancelled"} />
		}
	]

	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const firstRender = useRef(true);

	const channel = useSelector<RootState, ChannelData>(state => state.channel.selected as Required<ChannelData>);
	const bookingChannel = useSelector<RootState, number | null>(state => state.booking.bookingChannel);
	const bookingState = useSelector<RootState, BookingState>(state => state.booking);
	const tenants = useSelector<RootState, Tenant[]>(state => state.booking.tenants);
	const loading = useSelector<RootState, boolean>(state => state.app.loading);
	const bookingFilters = useSelector<RootState, BookingFilters>(state => state.booking.filters);
	const { bookings, count, offset, search } = bookingState;
	const scopes = useSelector<RootState, Required<string[]>>(state => state.auth.scopes);
	const channelSpecificScopes = useMemo(() => scopes.filter(item => item.includes(channel.name.toLowerCase())), [channel, scopes]);

	const [filters, setFilters] = useState<BookingFilters>(bookingFilters);
	const [filtersChanged, setFiltersChanged] = useState<boolean>(false);

	const showPriceColumn = useMemo(() => {
		const priceScope = getReadWritePermission(channelSpecificScopes.filter(item => item.includes('price')));
		return priceScope.read;
	}, [channelSpecificScopes])

	const hideCommissionColumn = useMemo(() => {
		const tenantScope = channelSpecificScopes.find(item => item.includes('tenant'));
		return tenantScope?.includes("owner") ? false : true;
	}, [channelSpecificScopes])

	const hiddenColumns = useMemo(() => {
		const cols = [];
		if (!showPriceColumn) cols.push('col9');
		if (hideCommissionColumn) cols.push('col8');
		return cols;
	}, [showPriceColumn, hideCommissionColumn]);

	useEffect(() => {
		setFilters(bookingFilters);
	}, [bookingFilters])

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		setFiltersChanged(true);
	}, [filters])

    useEffect(() => {
        /*
                Getting unique tenant ids and making calls for each of them.
         */
        const ids: number[] = [];
        bookings.forEach((booking) => {
            booking.assignments.forEach((assignment) => {
                ids.push(assignment.tenant)
            })
        });
        const uniqueIds = new Set(ids);
        uniqueIds.forEach(id => dispatch(getTenant(id)));
    }, [bookings])

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		if (channel.id !== bookingChannel || ((channel.id === bookingChannel) && !bookings.length)) {
			loadBookings(0)
		}
	}, [channel.id])

	const resetFilters = () => {
		dispatch(setBookingFilters(filterInitialState));
		setFilters(filterInitialState);
		loadBookings(0, true);
	}

	const loadBookings = (offset: number, reset?: boolean) => {
		const updatedFilters = updateFilters(filters, search);

		dispatch(filterBookings({ channelId: channel.id, filters: reset ? filterInitialState : updatedFilters, offset }));
	}

    const data = useMemo<Data[]>(() => {
        return bookings.map((booking) => [
            {
                col1: booking.code,
                col2: booking.contact || null,
                col3: booking.assignments[0]?.luggage_count,
                col4: booking.assignments[0]?.service,
                col5: booking.created,
                col6: booking.assignments[0]?.from_datetime,
                col7: booking.assignments[0]?.to_datetime,
                col8: booking.commission || 0,
                col9: booking.commission || 0,
                col10: booking.status,
                col11: tenants.find(tenant => tenant.id === booking.assignments[0]?.tenant)?.name || ''
            }
        ]).flat() as Data[];
    }, [bookings, tenants])

    const columns = useMemo<Column<Data>[]>(() => {
        return [
            {
                Header: <IntlMessages id={'booking_list:order'}/>,
                accessor: 'col1',
                Cell: (cell: Cell<[{ value: string }]>) => <IntlMessages id={'booking_list:invoice:booking'}
                                                                         values={{bookingNumber: cell.value}}/>,
                disableSortBy: true
            },
            {
                Header: <IntlMessages id={'booking_list:customer'}/>,
                accessor: 'col2',
                Cell: (cell: Cell<[{ value: Contact }]>) => <div className='media'>
                    <div className='media-body'>{cell.value !== null && <>
                        <div>{`${cell.value.first_name} ${cell.value.last_name}`}</div>
                        <div className={classes.email}>{cell.value.email}</div>
                    </>} </div>
                </div>,
                sortType: (rowA: Row<Data>, rowB: Row<Data>) => {
                    return rowA.values.col2.email > rowB.values.col2.email ? 1 : -1;
                }
            },
            {
                Header: <IntlMessages id={'booking_list:label:luggage'}/>,
                accessor: 'col3',
                Cell: (cell: Cell<[{ value: number }]>) => <div>{cell.value}</div>
            },
            {
                Header: <IntlMessages id={'booking_list:label:service'}/>,
                accessor: 'col4',
                Cell: (cell: Cell<[{ value: string }]>) => <img
                    src={`${process.env.PUBLIC_URL}/assets/svg/${cell.value}.svg`}
                    alt="service"
                    className="img-fluid rounded-circle avatar"
                    width="25"
                    height="50"
                />
            },
            {
                Header: <IntlMessages id={'booking_list:order_date'}/>,
                Cell: (cell: Cell<[{ value: string }]>) => new Date(cell.value).toLocaleDateString("en-GB", {
                    year: "numeric",
                    day: "numeric",
                    month: "short"
                }),
                accessor: 'col5'
            },
            {
                Header: <IntlMessages id={'booking_list:pickup_date'}/>,
                accessor: 'col6',
                Cell: (cell: Cell<[{ value: string }]>) => new Date(cell.value).toLocaleDateString("en-GB", {
                    year: "numeric",
                    day: "numeric",
                    month: "short"
                })
            },
            {
                Header: <IntlMessages id={'booking_list:delivery_date'}/>,
                accessor: 'col7',
                Cell: (cell: Cell<[{ value: string }]>) => new Date(cell.value).toLocaleDateString("en-GB", {
                    year: "numeric",
                    day: "numeric",
                    month: "short"
                })
            },
            {
                Header: <IntlMessages id={'booking_list:commission'}/>,
                accessor: 'col8',
                Cell: (cell: Cell<[{ value: Commission }]>) => {
                    const commissionData = getCommission(cell.value, channelSpecificScopes);
                    if (commissionData) {
                        const {commission, currencyCode, currencyExponent} = commissionData;
                        return price(currencyCode, commission, currencyExponent);
                    }
                    return 0;
                },
                sortType: (rowA: Row<Data>, rowB: Row<Data>) => {
                    return rowA.values.col7.total > rowB.values.col7.total ? 1 : -1;
                }
            },
            {
                Header: <IntlMessages id={'booking_list:price'}/>,
                accessor: 'col9',
								show: showPriceColumn,
                Cell: (cell: Cell<[{ value: Commission }]>) => {
                    let currencyCode = "";
                    let currencyExponent = 0;

                    const commissionData = cell.value as Commission;

                    const tenantContractKey = Object.keys(cell.value.total).find(key => cell.value.total[key].role === 'tenant');
                    if (tenantContractKey) {
                        const tenantContract = cell.value.total[tenantContractKey];
                        currencyCode = tenantContract.currency.code;
                        currencyExponent = tenantContract.currency.exponent;
                    }

                    const totalCommission = Object.values(commissionData.total).reduce((acc, contract) => {
                        const fullValue = calculatePrice(contract.commission, contract.currency.exponent);
                        return acc + fullValue;
                    }, 0);

                    if (!currencyCode || !currencyExponent) return 0;

                    return new Intl.NumberFormat("nl-NL", {
                        style: "currency",
                        currency: currencyCode,
                    }).format(totalCommission);
                },
                sortType: (rowA: Row<Data>, rowB: Row<Data>) => {
                    return rowA.values.col7.total > rowB.values.col7.total ? 1 : -1;
                }
            },
            {
                Header: <IntlMessages id={'booking_list:status'}/>,
                accessor: 'col10',
                Cell: (cell: Cell<[{ value: string }]>) => <span
                    className={`badge badge-${getBadgeClass(cell.value)}`}>{getStatus(cell.value.toUpperCase())} </span>
            },
            {
                Header: <IntlMessages id={'booking_list:tenant'}/>,
                accessor: 'col11',
                Cell: (cell: Cell<[{ value: string }]>) => <div>{cell.value}</div>
            }
        ];
    }, [showPriceColumn, channelSpecificScopes, classes.email]);
	

	const openBooking = async (id: string) => {
		await dispatch(getBookingDetails(channel.id, id))
		history.push(`bookings/${id}`, { id })
	}

	return (
		<React.Fragment>
			<div className="filters-wrapper">
				<div className="filters">
					<div className={classes.date}>
						<FormControl className={classes.input}>
							<FormLabel id="demo-radio-buttons-group-label">Date filters</FormLabel>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								value={filters.date}
								name="radio-buttons-group"
								className={classes.radioInput}
								onChange={(e) => setFilters((prev) => ({ ...prev, date: e.target.value }))}
							>
								<FormControlLabel value="order" control={<Radio />} label="Order" />
								<FormControlLabel value="pickup" control={<Radio />} label="Pickup" />
							</RadioGroup>
						</FormControl>
						<div className={classes.filters}>
							<KeyboardDatePicker
								autoOk
								variant="inline"
								format="DD/MM/YYYY"
								label={<IntlMessages id={"booking_list:label:start_date"} />}
								value={filters.from_datetime}
								onChange={(date) => setFilters((prev) => ({ ...prev, from_datetime: date?.utcOffset(0).startOf('day').toISOString() || filterInitialState.from_datetime }))}
								className={classes.input}
								size={'small'}
								InputProps={{
									id: 'bookings-start-date',
									endAdornment: ((filters.from_datetime &&
										<IconButton className={classes.iconButton}
											onClick={() => setFilters((prev) => ({ ...prev, from_datetime: filterInitialState.from_datetime }))}>
											<ClearIcon />
										</IconButton>))
								}}
							/>
							<KeyboardDatePicker
								autoOk
								variant="inline"
								format="DD/MM/YYYY"
								label={<IntlMessages id={"booking_list:label:end_date"} />}
								value={filters.to_datetime}
								onChange={(date) => setFilters((prev) => ({ ...prev, to_datetime: date?.utcOffset(0).endOf('day').toISOString() || filterInitialState.to_datetime }))}
								className={classes.input}
								size={'small'}
								InputProps={{
									id: 'bookings-end-date',
									endAdornment: ((filters.to_datetime &&
										<IconButton className={classes.iconButton}
											onClick={() => setFilters((prev) => ({ ...prev, to_datetime: filterInitialState.to_datetime }))}>
											<ClearIcon />
										</IconButton>))
								}}
							/>
						</div>
					</div>
					<div className={classes.selectors}>
						<FormControl className={classes.input}>
							<FormLabel id="demo-radio-buttons-group-label">Selectors</FormLabel>
							<div className={classes.selectors}>
								<Select
									isClearable
									isMulti
									placeholder={<IntlMessages id={"booking_list:label:status"} />}
									defaultValue={[]}
									value={statusOptions.filter(option => filters.status?.includes(option.value))}
									name="status"
									id="status-select"
									options={statusOptions}
									className={classes.input}
									classNamePrefix="select"
									onChange={(statuses: { value: string, label: string }[]) => {
											setFilters((prev) => ({
													...prev,
													status: statuses.map(status => status.value)
											}))
									}}
								/>
								<Select
									isClearable
									placeholder={<IntlMessages id={"booking_list:label:service"} />}
									defaultValue={[]}
									value={serviceOptions.find(option => option.value === filters.service)}
									name="service"
									id="type-select"
									options={serviceOptions}
									className={classes.input}
									classNamePrefix="select"
									onChange={(e: { value: ServiceType, label: string }) => {
										setFilters((prev) => ({
											...prev,
											service: e?.value || null
										}))
									}}
								/>
							</div>
						</FormControl>
					</div>
				</div>

				<div className={classes.buttonContainer}>
					<CsvDownload
						disabled={false}
						changed={filtersChanged}
						className={classes.button}
						channelId={channel.id}
						filters={filters}
						offset={0}
						limit={count}
					/>
					<div className="d-flex">
						<Button type="button" id="bookings-submit-button" className={classes.button} color="primary"
							variant="contained"
							onClick={() => {
								setFiltersChanged(false);
								dispatch(setBookingFilters(filters));
								loadBookings(0)
							}}>
							<IntlMessages id={"search:button:apply"} />
						</Button>
						<Button type="button" id="bookings-reset-button" className={classes.button} color="secondary"
							variant="contained"
							onClick={resetFilters}>
							<IntlMessages id={"search:button:reset"} />
						</Button>
					</div>
				</div>
			</div>
			<div className="table-responsive">
				{loading && <CustomSpinner />}
				<Table columns={columns} data={data} open={(id: string) => openBooking(id)}
					update={() => loadBookings(offset)}
					hasMore={!!offset && count !== bookings.length} hiddenColumns={hiddenColumns} />
			</div>
		</React.Fragment>
	);
}

export default BookingList;
