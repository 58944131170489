import {useEffect, useState} from "react";
import IntlMessages from "../../Util/IntlMessages"
import PageTitleBar from "../../Components/PageTitleBar/PageTitleBar";
import BookingList from "../../Components/BookingList/BookingList";
import {RctCard} from "../../Components/RctCard";
import {Route, RouteComponentProps, Switch, useLocation} from "react-router-dom";
import BookingDetails from "../../Components/BookingDetails";
import {generateToken, onForegroundMessage} from "firebaseSetup";
import Fetcher from "Util/Fetcher";
import {Bounce, toast} from "react-toastify";
import {makeStyles} from "@material-ui/core";

interface RouteState {
    id: string
}

const useStyles = makeStyles({
    notificationContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    notificationTitle: {
        fontSize: '100%',
        lineHeight: '1.5',
    },
    notificationBody: {
        fontSize: '14px',
        marginBottom: 0,
        lineHeight: '1.5',
    },
    "@global": {
        ".Toastify__toast-body": {
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            marginTop: 0,
        },
        ".Toastify__toast-icon": {
            margin: '1px 6px auto 0px'
        },
        ".Toastify__close-button": {
            marginLeft: 'auto',
            marginTop: '3px'
        },
    },
});

export default function Bookings({...props}: RouteComponentProps) {

    const classes = useStyles();

    const location = useLocation();
    const state = location.state as RouteState;
    const id = state?.id || undefined;

    const [notification, setNotification] = useState<{ title: string, body: string } | null>(null);

    useEffect(() => {
        const sendNotificationToken = async () => {
            try {
                const token = await generateToken();
                if (!token) return;

                await Fetcher({
                    path: 'notification/devices/',
                    method: "POST",
                    body: {
                        token,
                        source: "web"
                    }
                });
            } catch (error) {
                console.error(error);
                toast.error(<IntlMessages id={"notification:error"}/>);
            }
        };

        sendNotificationToken();
    }, []);

    useEffect(() => {
        const handleForegroundMessage = async () => {
            try {
                const payload = await onForegroundMessage();

                if (!payload.notification) throw Error;

                const {notification} = payload;
                const title = notification.title || "";
                const body = notification.body || "";

                setNotification({title, body});

                toast.info(
                    <div className={classes.notificationContainer}>
                        <strong className={classes.notificationTitle}>{title}</strong>
                        <p className={classes.notificationBody}>{body}</p>
                    </div>, {
                        position: "top-right",
                        autoClose: 20000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });

            } catch {
                toast.error(<IntlMessages id={"notification:error"}/>);
            }
        };

        handleForegroundMessage();
    }, [notification]);

    return (
        <div>
            <PageTitleBar
                icon="ti-shopping-cart"
                title={<IntlMessages id="sidebar.bookings"/>}
                match={props.match}
                id={id}
            />
            <RctCard children={
                <Switch>
                    <Route exact path='/app/bookings' component={BookingList}/>
                    <Route path=':id' component={BookingDetails}/>
                </Switch>
            }/>
        </div>
    );
}
