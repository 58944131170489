interface Environment {
    REACT_APP_API_URL: string;
    REACT_APP_CLIENT_CUSTOMER_ID: string;
    REACT_APP_CLIENT_CUSTOMER_SECRET: string;
    REACT_APP_CLIENT_PASSWORD_RECOVER_ID: string;
    REACT_APP_CLIENT_PASSWORD_RECOVER_SECRET: string;
    REACT_APP_ENV: string;
    REACT_APP_ANALYTICS_HOST: string;
    REACT_APP_RESULT_LIMIT: string;

    [key: string]: string;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const evars: Environment = process.env || {};

const ENV = {
    API_URL: `${evars.REACT_APP_API_URL}/1.0`,
    CLIENT_CUSTOMER_ID: evars.REACT_APP_CLIENT_CUSTOMER_ID,
    CLIENT_CUSTOMER_SECRET: evars.REACT_APP_CLIENT_CUSTOMER_SECRET || '',
    CLIENT_PASSWORD_RECOVER_ID: evars.REACT_APP_CLIENT_PASSWORD_RECOVER_ID,
    CLIENT_PASSWORD_RECOVER_SECRET: evars.REACT_APP_CLIENT_PASSWORD_RECOVER_SECRET || '',
    ENV: evars.REACT_APP_ENV,
    ANALYTICS_HOST: evars.REACT_APP_ANALYTICS_HOST,
    RESULT_LIMIT: Number(evars.REACT_APP_RESULT_LIMIT) || 30
};

export default ENV;
