import {ButtonBase, Theme, Typography} from "@material-ui/core";
import IntlMessages from "../../../Util/IntlMessages";
import {useLayoutEffect, useMemo, useRef, useState} from "react";
import {DetailedBooking, Tenant, Location, DetailedAssignment, Commission} from "../../../Store/bookings/bookingsTypes";
import {getBadgeClass, getStatus, price} from "../../../Util/utils";
import Table, {Data, Image} from "../../Table/Table";
import {useSelector} from "react-redux";
import RootState from "../../../Store/RootState";
import {Cell, Column} from "react-table";
import {DateTime} from 'luxon'
import {ChannelData} from "Store/channel/channelTypes";
import ImageViewer from "Components/ImageViewer/ImageViewer";
import {Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core';

const filterCommissionsByScope = (commissions: Commission, scopes: string[]) => {
    return Object.keys(commissions.total).map(key => ({
        contractName: key,
        ...commissions.total[key]
    })).filter(contract => {
        const role = contract.role.replace(' ', '_');
        return scopes.some(scope => scope.includes(role));
    });
};

const useStyles = makeStyles((theme: Theme) => ({
    accordionSummary: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: "20px",
        height: '240px',
        overflow: 'hidden',
        '& > div:first-child': {
            display: 'flex',
            gap: '20px',
            margin: '20px 0',
            flexWrap: 'nowrap',
        },
    },
    accordionDetails: {
        marginBottom: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: "20px",
        padding: '0 50px 0 20px',
    },
    buttonBase: {
        width: 'calc(50% - 20px)',
        display: 'flex',
        justifyContent: 'flex-start',
        [theme.breakpoints.up('lg')]: {
            width: 'calc(33.33% - 20px)',
        },
        [theme.breakpoints.up('xl')]: {
            width: 'calc(20% - 20px)',
        },
    },
    image: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
    },
}));

const Details = ({booking}: { booking: DetailedBooking }) => {

    const classes = useStyles();
    const [modalOpen, setModalOpen] = useState(false);
    const [images, setImages] = useState<Image[]>([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const channel = useSelector<RootState, ChannelData>(state => state.channel.selected as Required<ChannelData>);
    const tenants = useSelector<RootState, Tenant[]>(state => state.booking.tenants);
    const assignments = useSelector<RootState, DetailedAssignment[] | undefined>(state => state.booking.detail?.assignments);
    const scopes = useSelector<RootState, Required<string[]>>(state => state.auth.scopes);

    const channelSpecificScopes = useMemo(() => {
        return scopes.filter(item => item.includes(channel.name.toLowerCase()))
    }, [channel, scopes]);

    const hideCommission = useMemo(() => {
        const tenantScope = channelSpecificScopes.find(item => item.includes('tenant'));
        return tenantScope?.includes("owner") ? false : true;
    }, [channelSpecificScopes])

    const assignmentData = useMemo<Data[]>(() => {
        return booking.assignments.map((assignment) => [
            {
                col1: assignment.id,
                col2: assignment.status,
                col3: tenants.find(tenant => tenant.id === assignment.tenant)?.name || '',
                col4: assignment.from_location.name,
                col5: assignment.to_location.name,
            }
        ]).flat() as Data[];
    }, [booking, tenants])

    const jobsData = useMemo<Data[]>(() => {
        return booking.assignments.map((assignment) => [
            {
                col1: `${DateTime.fromISO(assignment.from_datetime_local).toFormat('yyyy LLL dd HH:mm')}|${assignment.from_timezone}`,
                col2: `${DateTime.fromISO(assignment.to_datetime_local).toFormat('yyyy LLL dd HH:mm')}|${assignment.to_timezone}`,
            }
        ]).flat() as Data[];
    }, [booking, tenants])

    const commissionData = useMemo<Data[]>(() => {
        const commissionContracts = filterCommissionsByScope(booking.commission, channelSpecificScopes);
        return commissionContracts.map(contract => [
            {
                col1: contract.contractName,
                col2: contract.claim_type,
                col3: contract.currency.name,
                col4: price(contract.currency.code, contract.commission, contract.currency.exponent)
            }
        ]).flat() as Data[];
    }, [booking, channelSpecificScopes]);

    const imageData = useMemo<Data[]>(() => {
        if (!assignments || assignments.length === 0) return [];

        const luggageItems = assignments[0].luggage.map((luggageItem) => ({
            label: luggageItem.identifier.uuid,
            images: luggageItem.images.map((image, index) => ({
                src: `data:image/jpeg;base64,${image.image}`,
                alt: "",
                key: `${index}-${luggageItem.identifier.uuid}`
            }))
        }));

        return luggageItems.map(item => [
            {
                col1: item.label,
                col2: item.images
            }
        ]).flat() as unknown as Data[]
    }, []);

    const columns = useMemo<Column<Data>[]>(() => ([
        {
            Header: <IntlMessages id={'booking:detail:details:id'}/>,
            accessor: 'col1',
            Cell: (cell: Cell<[{ value: number }]>) => <IntlMessages id={'booking:detail:details:id_value'}
                                                                     values={{id: cell.value}}/>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:assignment_status'}/>,
            accessor: 'col2',
            Cell: (cell: Cell<[{ value: string }]>) => <span
                className={`badge badge-${getBadgeClass(cell.value)}`}>{getStatus(cell.value).toUpperCase()}</span>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:tenant'}/>,
            accessor: 'col3',
            Cell: (cell: Cell<[{ value: string }]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:pickup'}/>,
            accessor: 'col4',
            Cell: (cell: Cell<[{ value: Location }]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:delivery'}/>,
            accessor: 'col5',
            Cell: (cell: Cell<[{ value: Location }]>) => <div>{cell.value}</div>
        }
    ]), [])

    const jobsColumns = useMemo<Column<Data>[]>(() => ([
        {
            Header: <IntlMessages id={'booking:detail:details:pickup_time'}/>,
            accessor: 'col1',
            Cell: (cell: Cell<[{ value: string }]>) =>
                <div>
                    <div>{cell.value.split("|")[0]}</div>
                    <div>{cell.value.split("|")[1]}</div>
                </div>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:delivery_time'}/>,
            accessor: 'col2',
            Cell: (cell: Cell<[{ value: string }]>) =>
                <div>
                    <div>{cell.value.split("|")[0]}</div>
                    <div>{cell.value.split("|")[1]}</div>
                </div>
        }
    ]), [])

    const commissionColumns = useMemo<Column<Data>[]>(() => ([
        {
            Header: <IntlMessages id={'booking:detail:details:provider'}/>,
            accessor: 'col1',
            Cell: (cell: Cell<[{ value: string }]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:claim_type'}/>,
            accessor: 'col2',
            Cell: (cell: Cell<[{ value: string }]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:currency'}/>,
            accessor: 'col3',
            Cell: (cell: Cell<[{ value: string }]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:details:commission'}/>,
            accessor: 'col4',
            Cell: (cell: Cell<[{ value: string }]>) => <div>{cell.value}</div>
        }
    ]), [])

    const imageColumns = useMemo<Column<Data>[]>(() => [
        {
            Header: <IntlMessages id={'booking:detail:details:id'}/>,
            accessor: 'col1',
            Cell: (cell: Cell<[{ value: number }]>) => (
                <IntlMessages id={'booking:detail:details:id_value'} values={{id: cell.value}}/>
            )
        },
        {
            Header: "Images",
            accessor: "col2",
            Cell: (cell: Cell<[{ value: Image[] }]>) => {
                const [visibleImages, setVisibleImages] = useState<Image[]>([]);
                const [hiddenImages, setHiddenImages] = useState<Image[]>([]);
                const summaryRef = useRef<HTMLDivElement>(null);
                const images = cell.value;

                useLayoutEffect(() => {
                    const updateImages = () => {
                        if (summaryRef.current && images) {
                            const summaryContent = summaryRef.current.firstElementChild as HTMLDivElement;
                            const summaryWidth = summaryContent?.getBoundingClientRect().width || 0;
                            const imageWidth = 200;
                            const imagesPerRow = Math.floor(summaryWidth / imageWidth);

                            const newVisibleImages = images.slice(0, imagesPerRow);
                            const newHiddenImages = images.slice(imagesPerRow);

                            if (
                                newVisibleImages.length !== visibleImages.length ||
                                newHiddenImages.length !== hiddenImages.length
                            ) {
                                setVisibleImages(newVisibleImages);
                                setHiddenImages(newHiddenImages);
                            }
                        }
                    };

                    updateImages();

                    window.addEventListener('resize', updateImages);

                    return () => {
                        window.removeEventListener('resize', updateImages);
                    };
                }, [images, visibleImages.length, hiddenImages.length]);

                if (!images || !images.length) {
                    return <div><IntlMessages id={'booking:detail:details:no_images'}/></div>;
                }

                return (
                    <Accordion>
                        <AccordionSummary
                            className={classes.accordionSummary}
                            expandIcon={hiddenImages.length > 0 ? <ExpandMoreIcon/> : null}
                            ref={summaryRef}
                        >
                            {visibleImages.map((img, index) => (
                                <ButtonBase
                                    key={img.key}
                                    onClick={(e) => handleOpenModal(e, cell.value, index)}
                                    className={classes.buttonBase}
                                >
                                    <img src={img.src} alt={img.alt} className={classes.image}/>
                                </ButtonBase>
                            ))}
                        </AccordionSummary>
                        {hiddenImages.length > 0 && (
                            <AccordionDetails className={classes.accordionDetails}>
                                {hiddenImages.map((img, index) => (
                                    <ButtonBase
                                        key={img.key}
                                        onClick={(e) => handleOpenModal(e, cell.value, index + visibleImages.length)}
                                        className={classes.buttonBase}
                                    >
                                        <img src={img.src} alt={img.alt} className={classes.image}/>
                                    </ButtonBase>
                                ))}
                            </AccordionDetails>
                        )}
                    </Accordion>
                );
            }
        }
    ], [classes]);

    const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, images: Image[], index: number) => {
        e.stopPropagation();
        setImages(images);
        setActiveIndex(index);
        setModalOpen(true);
    };

    return (
        <div>
            <Typography variant={'h6'} className="mb-3"><IntlMessages
                id={"booking:detail:details:details"}/></Typography>
            <div>
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label mb-0"><IntlMessages
                        id={"booking:detail:details:code"}/></label>
                    <div className="col-sm-10">{booking.code}</div>
                </div>
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label mb-0"><IntlMessages
                        id={"booking:detail:details:flight_number"}/></label>
                    <div className="col-sm-10">{booking.meta.flightNumber}</div>
                </div>
                {booking.meta?.membership && <div className="row form-group">
                    <label className="col-sm-2 col-form-label mb-0"><IntlMessages
                        id={"booking:detail:details:membership_number"}/></label>
                    <div className="col-sm-10">{booking.meta.membership}</div>
                </div>}
                <div className="row form-group">
                    <label className="col-sm-2 col-form-label mb-0"><IntlMessages
                        id={"booking:detail:details:booking_status"}/></label>
                    <div className="pl-10"><span
                        className={`badge badge-${getBadgeClass(booking.status)}`}>{getStatus(booking.status.toUpperCase())} </span>
                    </div>
                </div>
            </div>

            <Typography variant={'h6'} className="mt-3 mb-2"><IntlMessages
                id={"booking:detail:details:assignments"}/></Typography>
            <Table data={assignmentData} columns={columns}/>

            <Typography variant={'h6'} className="mt-3 mb-2"><IntlMessages
                id={"booking:detail:details:images"}/></Typography>
            <Table data={imageData} columns={imageColumns}/>

            <Typography variant={'h6'} className="mt-3 mb-2"><IntlMessages
                id={"booking:detail:details:jobs"}/></Typography>
            <Table data={jobsData} columns={jobsColumns}/>

            {!hideCommission &&
                <>
                    <Typography variant={'h6'} className="mt-3 mb-2"><IntlMessages
                        id={"booking:detail:details:commissions"}/></Typography>
                    <Table data={commissionData} columns={commissionColumns}/>
                </>
            }

            <ImageViewer
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                images={images}
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
            />
        </div>
    )
}

export default Details;