import {initializeApp} from "firebase/app";
import {getMessaging, getToken, MessagePayload, onMessage, isSupported} from "firebase/messaging";

export const firebaseConfig = {
    apiKey: "AIzaSyCCJ_sSdb9Vjux8FT1A8igiG0wobDlvnWI",
    authDomain: "fir-bagpoint-test.firebaseapp.com",
    projectId: "fir-bagpoint-test",
    storageBucket: "fir-bagpoint-test.appspot.com",
    messagingSenderId: "100470584975",
    appId: "1:100470584975:web:fc9148fbc8a97784bc22fa",
    measurementId: "G-5D6NFGVCD3"
};

export const vapidKey = 'BF3sks0M7_I65l8wFbbfJya_tKyZJW31QMFtqql2M2DpYHQpP4oe-tjNqg9nxJTE57T66DEJU206pRTjRgIbbYU';

const app = initializeApp(firebaseConfig);

export const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(app);
        }
        console.log('Firebase Messaging is not supported on this browser.');
        return null;
    } catch (err) {
        console.error('Error during Firebase Messaging initialization:', err);
        return null;
    }
})();

export const onForegroundMessage = async (): Promise<MessagePayload> => {
    const msg = await messaging;
    return new Promise<MessagePayload>((resolve, reject) => {
        if (msg) {
            onMessage(msg, (payload: MessagePayload) => resolve(payload));
        } else {
            reject("Messaging is not supported in this environment.");
        }
    });
};
export const generateToken = async (): Promise<string | undefined> => {
    const permission = await Notification.requestPermission();

    if (permission === "denied") return;

    const msg = await messaging;
    if (msg) {
        return await getToken(msg, {vapidKey});
    } else {
        console.warn("Messaging is not supported, no token generated.");
        return undefined;
    }
};
